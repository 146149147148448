import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import { Layout } from 'containers';
import { Banner } from 'components';
import { BASE_URL } from 'config';

const showdown = require('showdown');

const converter = new showdown.Converter();

const TourPage = ({ data }) => (
  <Layout page="tour/young-adventuress">

    <Helmet
      title="Young Adventuress NZ Tour"
      meta={[
        {
          name: 'description', content: 'Join Young Adventuress on this special NZ Tour',
        },
        { name: 'og:title', content: 'Young Adventuress NZ Tour' },
        {
          name: 'og:description', content: 'Join Young Adventuress on this special NZ Tour',
        },
      ]}
      link={[{ rel: 'canonical', href: `${BASE_URL}/tour/young-adventuress/` }]}
    />

    <Banner modifier="c-banner--tiny" backgroundImage="https://static-hakatours.imgix.net/young-adventuress.jpg?auto=format">
      <h1 className="c-heading c-heading--h1">Young Adventuress NZ Tour</h1>
    </Banner>
    <section className="l-section">
      <div className="l-container l-container--small">
        <div
          dangerouslySetInnerHTML={{
            __html: converter.makeHtml(data.allStaticPages.edges[0].node.content_markdown),
          }}
        />
      </div>
    </section>
  </Layout>
);

TourPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TourPage;

export const query = graphql`
  query {
    allStaticPages(
      filter: {
        slug: { eq: "young-adventuress" },
      }
    ) {
      edges {
        node {
          id
          api_id
          slug
          content_markdown
        }
      }
    }
  }
`;
